<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>广告管理</el-breadcrumb-item>
            <el-breadcrumb-item>添加/修改</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">

        <el-form-item label="序号" :error="$v.form.sorder.$error ? '序号' : ''">
          <el-input v-model.number="form.sorder"></el-input>
        </el-form-item>
        <el-form-item label="页面属性" :error="$v.form.advPosition.$error ? '请选择显示位置' : ''">
          <el-radio-group v-model="form.advPosition">
            <el-radio :label=0>底部菜单</el-radio>
            <el-radio :label=1>内页</el-radio>

          </el-radio-group>
        </el-form-item>

        <el-form-item label="显示位置" :error="$v.form.iType.$error ? '请选择显示位置' : ''">
          <el-radio-group v-model="form.iType">
            <el-radio :label=0>轮播图（大）</el-radio>

            <el-radio :label=5>首页banner图1.1</el-radio>
            <el-radio :label=6>品牌起源1.1</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="广告名称" :error="$v.form.advName.$error ? '请输入广告名称' : ''">
          <el-input v-model="form.advName"></el-input>
        </el-form-item>
        <el-form-item label="广告图片" :error="$v.form.advImg.$error ? '请上传广告图片' : ''">
          <image-upload @upload="imageUpload" :image="form.advImg" />
        </el-form-item>

        <el-form-item label="小程序页面路径">
          <el-input v-model="form.advUrl"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.state">
            <el-radio :label=1>启用</el-radio>
            <el-radio :label=0>禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="小程序页面路径说明">

          <el-card class="box-card">
            <div class="text item"> 分类：pages/goods/classify?id= </div>
            <div class="text item"> 商品详情：pages/goods/detail?id= </div>
            <div class="text item"> 我的 pages/my/index </div>
            <div class="text item"> 积分兑换：pages/my/points-mall</div>
            <div class="text item"> 我的优惠券：pages/my/coupon </div>
          </el-card>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import ImageUpload from '@/components/imageUpload'
import { getdatilRequest, editRequest } from '@/api/adv'
import { required } from 'vuelidate/lib/validators'
import utils from '@/utils/util'
export default {
  name: 'advedit',
  data() {
    return {
      form: {
        id: 0,
        typeName: '',
        advImg: '',
        typePrice: 0,
        directpromotion: 0,
        staffReward: 0,
        states: 0,
        addDate: '',
        remarks: '',
        iType: 0,
        state: 0,
      },
    }
  },
  components: {
    'image-upload': ImageUpload,
  },
  created() {
    this.form.id = this.$route.query['id']
    this.getdetail()
  },
  methods: {
    back() {
      this.$router.back()
    },
    getdetail() {
      getdatilRequest({
        Id: this.form.id,
      }).then((res) => {
        console.log(res)

        this.form = res.data
      })
    },
    imageUpload(url) {
      this.form.advImg = url
    },
    save() {
      this.$v.form.$touch()
      let params1 = JSON.parse(JSON.stringify(this.form))
      console.log(params1)
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form))
        params.advImg = utils.removeUrl(params.advImg)

        editRequest(params).then((res) => {
          if (res.code == 1) {
            this.$router.go(-1)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
  },
  validations: {
    form: {
      advName: {
        required,
      },
      advImg: {
        required,
      },
      sorder: {
        required,
      },
      advPosition: {
        required,
      },
      iType: {
        required,
      },
    },
  },
}
</script>

<style lang="less" scoped>

</style>
