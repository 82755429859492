import request from '@/utils/request'

// 获取列表
export function getListRequest(params) {
  return request({
    url: '/admin/SysAdvertisement/getList',
    method: 'get',
    params
  })
}

// 获取详情信息
export function getdatilRequest(params) {
  return request({
    url: '/admin/SysAdvertisement/getdatil',
    method: 'get',
    params
  })
}

// 添加信息
export function addRequest(data) {
  return request({
    url: '/admin/SysAdvertisement/add',
    method: 'post',
    data
  })
}

// 修改信息
export function editRequest(data) {
  return request({
    url: '/admin/SysAdvertisement/edit',
    method: 'post',
    data
  })
}

// 删除信息
export function delRequest(params) {
  return request({
    url: '/admin/SysAdvertisement/del',
    method: 'get',
    params
  })
}

// 修改状态
export function setStatesRequest(params) {
  return request({
    url: '/admin/SysAdvertisement/setStates',
    method: 'get',
    params
  })
}



// 修改排序
export function setSortRequest(params) {
  return request({
    url: '/admin/SysAdvertisement/setSort',
    method: 'get',
    params
  })
}